<template>
  
  <div class="c-app flex-row align-items-center">
    <b-overlay :show="show" rounded="sm">
    <div class="container-fluid bg-white">
      <CRow class="justify-content-center">
        <CCol md="12" class="px-0">
          <CCardGroup class="group-wrapper">
            <CCard
              color="dark"
              text-color="white"
              class="text-center py-5 d-md-down-none px-4 box-left"
              body-wrapper
            >
              <CCardBody class="mt-2">
                <h1>BACK TO</h1>
                <h2>ONLINE SCHOOL</h2>

                <img
                  src="img/MahaVidya/back-to-school.png"
                  class="logo-img img-fluid left-img"
                />
              </CCardBody>
            </CCard>

            <CCard class="p-5 border-0">
               <!-- <Loading 
                :active.sync="isLoading"
                  :is-full-page="fullPage"
                  color="#ffc107"
                  loader="bars"
                  height:150
                  ></Loading> -->
              <CCardBody class="">
                <CForm class="pt-5">
                  <CIcon
                    class="c-sidebar-brand-full logo-img"
                    name="logo"
                    size="custom-size"
                    :height="150"
                    viewBox="0 0 556 334"
                  />

                  <CRow>
                    <CCol col="sm-8" class="offset-sm-2">
                      <!-- <p class="text-muted mt-3">Sign In to your account</p> -->
                      <p class="text-muted mt-3">{{labels.title}}</p>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="sm-8" class="offset-sm-2">
                      <CInput
                        :placeholder="labels.username"
                        autocomplete="username email"
                        v-model="username"
                      >
                        <template #prepend-content
                          ><CIcon name="cil-user"
                        /></template>
                        
                      </CInput>
                      <span id="user_err"></span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="sm-8" class="offset-sm-2">
                      <CInput
                        :placeholder="labels.password"
                        type="password"
                        autocomplete="curent-password"
                        v-model="password"
                      >
                        <template #prepend-content
                          ><CIcon name="cil-lock-locked"
                        /></template>
                      </CInput>
                      <span id="password_err"></span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="sm-8" class="offset-sm-2">
                      <div class="form-group">
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              class="form-check-input primary"
                              type="checkbox"
                            />
                            {{labels.rememberMe}}
                          </label>
                        </div>
                      </div>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol col="sm-8" class="offset-sm-2">
                      <CRow>
                        <CCol col="6" class="text-left">
                          <CButton color="primary" class="px-4" @click="login_details">{{labels.submit}}</CButton>
                        </CCol>
                        <CCol col="6" class="text-right"> </CCol>
                      </CRow>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol col="sm-8" class="offset-sm-2 text-right">
                      <CButton color="link" class="px-0"
                        >Forgot password?</CButton
                      >
                      <CButton color="link" class="">Register now!</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </div>
  </b-overlay>
  </div>
</template>

<script>
import axios from 'axios';
import apis from '@/apis';
import VueSession from 'vue-session';
import loading from 'vue-full-loading';
import $ from 'jquery';
//import Loading from 'vue-loading-overlay';
//import Loading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/vue-loading.css';
const base_url=process.env.VUE_APP_BASE_URL;

export default {
  name: "Login",
  data:function(){
    return{
      show:false,
      username:"",
      my_label:"labels",
      password:"",
      labels: {
        title: "महा-विद्या मध्ये आपले स्वागत आहे",
        username: "वापरकर्ता",
        password: "परवलीचा शब्द",
        rememberMe: "माझे तपशील स्मृतीत राहू द्या",
        submit: "स्वीकारा",
        reset: "पुनर्स्थापित"
      },
      labelsMarathi: {
        title: "महा-विद्या मध्ये आपले स्वागत आहे",
        username: "वापरकर्ता",
        password: "परवलीचा शब्द",
        rememberMe: "माझे तपशील स्मृतीत राहू द्या",
        submit: "स्वीकारा",
        reset: "पुनर्स्थापित"
      },
      labelsEnglish: {
        title: "Welcome to Maha-Vidya",
        username: "User Name",
        password: "Password",
        rememberMe: "Remember Me",
        submit: "Submit",
        reset: "Reset"
      },
    }
  },
  methods:{
    login_details(){
     
      if(this.username == '' || this.username == undefined){
        $("#user_err").css("color","red").text("Username Required *").show();
        setTimeout(function(){
          $("#user_err").hide();
        },2000);
        return;
      }
      if(this.password == '' || this.password == undefined){
        $("#password_err").css("color","red").text("Password Required *").show();
        setTimeout(function(){
          $("#password_err").hide();
        },2000);
        return;
      }
      this.show = true;
      var data = {
        user_name: this.username,
        password: this.password
      };
      var promise = apis.Login(data).then( response => {
        this.show = false;
        this.login_data = response.data;
         var user_id = this.login_data[0].user_id;
          var user_name = this.login_data[0].user_name;
          var user_role_id = this.login_data[0].user_role_id;
          this.$session.start();
          this.$session.set("user_id", user_id);
          this.$session.set("user_role_id", user_role_id);
          localStorage.setItem('user_id',user_id);
          localStorage.setItem('user_name',user_name);
          localStorage.setItem('user_role_id',user_role_id);
          if(user_role_id == '8290f938-b20a-42e3-c201-08d8f3241655'){
            //window.location.reload();
            //this.$router.push('/student_dashboard');
             window.location.href = '#/student_dashboard';
             return;
          }else{
            window.location.href = '/';
          }
         // this.$router.push("/Dashboard");
      }).catch( errror => {
        
        this.show = false;
        alert("Something Wrong");
       // this.isLoading = false;
      })
    },

  },
  components:{
    VueSession,
    loading,
    
  //  Loading
  },
  created(){

  }
};
</script>

<style scoped>
.group-wrapper {
  height: 100vh;
}

.box-left h1 {
  font-size: 4rem;
}

.box-left h2 {
  font-size: 2rem;
}

.logo-img {
  display: block;
  width: 100%;
  margin: auto;
}

.box-left {
  border-radius: 0;
}
</style>
